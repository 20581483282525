import React from 'react';
import './App.css';
import 'font-awesome/css/font-awesome.min.css';

function App() {
  return (
      <div className="container">
        {/* Image */}
        <img src={process.env.PUBLIC_URL + '/profile.jpg'} alt="Profile"
             className="profile-image"/>

        {/* Info text */}
        <p className="info-text">keep it simple.</p>

        {/* Line separator */}
        <div className="separator"></div>

        {/* Email Presentation */}
        <div className="email-container">
          <i className="fa fa-envelope" aria-hidden="true"></i>
          <span className="email-text"><a
              href="mailto:hello@sergiuorian.com">hello@sergiuorian.com</a></span>
        </div>


        {/* Buttons */}
        <div className="buttons-container">
          <button className="download-button" onClick={() => downloadCV()}>Check
            my CV
          </button>
          <button className="blog-button"
                  onClick={() => window.open("https://medium.com/@oriansergiu",
                      "_blank")}>Check my Blog
          </button>
        </div>


        <footer className="footer">
          <a href="https://www.instagram.com/sergiuorian/" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-instagram"></i>
          </a>
          <a href="https://twitter.com/SergiuOrian" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-twitter"></i>
          </a>
          <a href="https://www.linkedin.com/in/sergiu-orian-1b8557150/" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-linkedin"></i>
          </a>
          <a href="https://github.com/sergiuorian" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-github"></i>
          </a>
          <a href="https://gitlab.com/oriansergiu" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-gitlab"></i>
          </a>
        </footer>
      </div>
  );
}

function downloadCV() {
  // Assuming the CV is named 'cv.pdf' and it's in the public folder
  window.location.href = process.env.PUBLIC_URL + '/Sergiu-Orian-CV.pdf';
}

export default App;
